import Factory from 'components/Factory';

interface IHomeProps {
    home: [any];
}

const HomeSections = ({ home }: IHomeProps) => {
    const homeSections = home?.filter((c: any) => c.sectionType !== 'HeaderSection'
        && c.sectionType !== "PromotionalVideoSection" && c.sectionType !== "Testimonials"
    ) || [];

    return (
        <>
            {homeSections.filter(c=> c?.showSection || c?.showSection === undefined || c?.showSection === null).map((element: any, i:number) => {
                return (<Factory key={i} type={element.sectionType} settings={element.value} />);
            })}
        </>
    );
};

export default HomeSections;

