import { AppContext } from 'context/appContext';
import dynamic from 'next/dynamic';
import { useContext } from 'react';

const Banner = dynamic(() => import('components/Banner/Banner'));
const HtmlComponent = dynamic(() => import('components/Html/Html'));
const AxTestimonial = dynamic(() => import('components/AxTestimonial/AxTestimonial'));
const SpecialVehiclesCarousel = dynamic(() => import('components/SpecialVehicles/SpecialVehicles'));
const SpecialVehiclesInCard = dynamic(() => import('components/SpecialVehicles/SpecialVehiclesInCard'));

interface IFactoryProps {
    type: string,
    settings: any,
}

const Factory = ({ type, settings }: IFactoryProps) => {
    const contexSettings = useContext(AppContext);

    const {useOrbeeAuto, showOrbeeInHome, widgetIdOrbeeAuto } = contexSettings?.settings.thirdParty;

    const getOrbee = () =>{
        if(useOrbeeAuto && showOrbeeInHome)
            return <section>
                <div className="ax-shopping-history-navigation"
                    dangerouslySetInnerHTML={{__html: `<orb id="orbee-personalized-experience"
                        data-type="widget"
                        data-widget-id="${widgetIdOrbeeAuto}"
                        data-default="hide">
                    </orb>`}} />
            </section>;

        return null;
    };


    switch (type) {
    case 'BannerSection':
        return <><Banner settings={settings}/>{getOrbee()}</>;
    case 'SpecialVehicles':
        return settings.showChoosenVehicles ? <SpecialVehiclesInCard settings={settings}/> : <SpecialVehiclesCarousel settings={settings}/>;
    case 'AxTestimonialSection':
        return <AxTestimonial settings={settings}/>;
    case 'html':
        return <HtmlComponent htmlValue={settings} />;
    default:
        return <></>;
    }
};

export default Factory;